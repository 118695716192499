.actionbar {
  background: #fff;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  color: var(--default-font-color);
  font-family: var(--default-font);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  position: -webkit-sticky;
  position: sticky;
  min-height: 55px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.actionbar__right {
  display: flex;
}

.actionbar__action {
  cursor: pointer;
  padding: 1rem;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.actionbar__action:hover {
  background: #e7e7e7;
}

.actionbar__action--danger {
  color: #e74c3c;
}

.actionbar__search {
  margin-left: 1rem;
  position: relative;
}

.actionbar__search svg {
  position: absolute;
  left: 1rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.actionbar select,
.actionbar__search input {
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  color: var(--default-font-color);
  height: 35px;
  border-radius: 5px;
  padding: 0 1rem 0 2.5rem;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 150px;
  -webkit-appearance: none;
  -webkit-border-radius: 5px;
  -moz-appearance: none;
}

.actionbar__search input:hover {
  background: #f4f4f4;
}

.actionbar__search input:focus {
  background: #fff;
  outline: none;
  box-shadow: 0 1px 2px 0 #ccc;
  width: 250px;
}

.actionbar select {
  background: #fff;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEyIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTUuODk0MTYgOUwwLjY3MDUxMSA5Ljc4Nzk5ZS0wN0wxMS4xMTc4IDcuMDI3NDZlLTA4TDUuODk0MTYgOVoiIGZpbGw9IiNDNEM0QzQiLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  cursor: pointer;
  padding: 0 1rem;
  margin-left: 1rem;
}

.actionbar select:focus {
  outline: none;
  box-shadow: 0 1px 2px 0 #ccc;
  width: 150px;
}

.actionbar select:hover {
  background-color: #f4f4f4;
}
