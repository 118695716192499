.select {
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  margin-bottom: 1rem;
}

.select__label {
  color: rgba(0, 0, 0, 0.54);
  font: 400 18px/20px var(--default-font);
  position: absolute;
  left: 20px;
  top: 19px;
  -webkit-transition: -webkit-transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transform-origin: left top;
          transform-origin: left top;
  z-index: 1;
}

.select__field {
  background: transparent;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.6);
  font: 400 18px/22px var(--default-font);
  width: 100%;
  height: 60px;
  outline: none;
  padding: 25px 17px 11px;
  position: relative;
  -webkit-transition: border 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: border 300ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2;
  -webkit-appearance: none;
  -webkit-border-radius: 5px;
  -moz-appearance: none;
}

.select:after {
  content: '';
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -5px;
  border-top: 10px solid #868686;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.select__field--success {
  border-color: #5d9b27;
}

.select__field--error {
  border-color: #ff8e8e;
}

.select__field:focus {
  border-color: #47baec;
}

.select__field:disabled {
  background-color: #f4f4f4;
}

.select__field:disabled ~ .select__label {
  color: var(--default-font-color);
  z-index: 3;
}

.select__field--filled ~ .select__label {
  color: var(--default-font-color);
  -webkit-transform: scale(0.7) translate(-15px, -15px);
          transform: scale(0.7) translate(-15px, -15px);
}
