.tabs {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.tabs__list {
  background: #254886;
  flex: 1 0 50px;
  position: relative;
}

.tabs__list ol {
  box-sizing: border-box;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.tabs__indicator {
  background: #fff;
  content: '';
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.tabs__item {
  font: 400 1rem/1rem var(--default-font);
  color: #fff;
  cursor: pointer;
  flex: 1 1;
  padding: 1rem;
  text-align: center;
}

.tabs__content {
  box-sizing: border-box;
  font-family: var(--default-font);
  font-size: 1rem;
  font-weight: 400;
  height: 100%;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap;
}

.tabs__content-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  overflow: auto;
  height: calc(100% - 50px);
  width: 100%;
  padding-bottom: 1rem;
}

@media (min-width: 1200px) {
  .tabs__list {
    background: none;
  }

  .tabs__item {
    flex: none;
    color: var(--default-font-color);
  }

  .tabs__item--active {
    text-decoration: underline;
    color: #254886;
  }

  .tabs__indicator {
    display: none;
  }
}
