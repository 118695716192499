.paginate {
  background: #fff;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  color: var(--default-font-color);
  font-family: var(--default-font);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  min-height: 50px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.paginate--noborder {
  padding: 0;
  border: none;
}

.paginate__page {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  padding: 1rem;
}

.paginate--noborder .paginate__page {
  border-radius: 5px;
}

.paginate__page:hover {
  background: #e7e7e7;
}

.paginate__page--active {
  background: #e7e7e7;
  cursor: default;
}

.paginate__page--ellipsis,
.paginate__page--ellipsis:hover {
  background: none;
  cursor: default;
  padding: 1rem 0.3rem;
}
