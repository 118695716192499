.tooltip {
  font-family: var(--default-font);
  font-size: 1rem;
  position: relative;
}

.tooltip:hover .tooltip__content {
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  opacity: 1;
  visibility: visible;
}

.tooltip__content {
  position: absolute;
  bottom: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, 25%);
          transform: translate(-50%, 25%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 999;
  padding-bottom: 1rem;
}

.tooltip__content .shadowy-box {
  position: relative;
}

.tooltip__content .shadowy-box::after {
  background: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  content: '';
  width: 12px;
  height: 12px;
  position: absolute;
  left: 50%;
  top: calc(100% - 12px);
  -webkit-transform: rotate(-45deg) translateX(-50%);
          transform: rotate(-45deg) translateX(-50%);
  z-index: 1;
}

.tooltip__content .shadowy-box::before {
  background: #fff;
  border-radius: 5px;
  content: '';
  width: 75%;
  height: 12px;
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 2;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

/* align to right */
.tooltip--right .tooltip__content,
.tooltip--left .tooltip__content {
  bottom: 50%;
  left: 100%;
  padding-bottom: 0;
  padding-left: 1rem;
  -webkit-transform: translate(-25%, 50%);
          transform: translate(-25%, 50%);
}

.tooltip--right:hover .tooltip__content,
.tooltip--left:hover .tooltip__content {
  -webkit-transform: translate(0, 50%);
          transform: translate(0, 50%);
}

.tooltip--right .shadowy-box::after,
.tooltip--left .shadowy-box::after {
  left: 0;
  top: 50%;
  -webkit-transform: rotate(-45deg) translateY(-50%);
          transform: rotate(-45deg) translateY(-50%);
}

.tooltip--right .shadowy-box::before,
.tooltip--left .shadowy-box::before {
  height: 75%;
  width: 12px;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

/* align to left */
.tooltip--left .tooltip__content {
  left: auto;
  right: 100%;
  padding-right: 1rem;
}

.tooltip--left:hover .tooltip__content {
  -webkit-transform: translate(0, 50%);
          transform: translate(0, 50%);
}

.tooltip--left .shadowy-box::after {
  left: auto;
  right: 0;
  -webkit-transform: rotate(45deg) translate(0, -50%);
          transform: rotate(45deg) translate(0, -50%);
}

.tooltip--left .shadowy-box::before {
  left: auto;
  right: 0;
}

/* align to bottom */
.tooltip--bottom .tooltip__content {
  bottom: auto;
  top: 100%;
  padding-top: 1rem;
  padding-bottom: 0;
}

.tooltip--bottom .shadowy-box::after {
  top: 0;
  left: 50%;
  -webkit-transform: rotate(45deg) translate(-50%, 0);
          transform: rotate(45deg) translate(-50%, 0);
}

.tooltip--bottom .shadowy-box::before {
  top: 0;
}
