.loading {
  display: inline-block;
}

.loading--full-page {
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.loading__content {
  border-radius: 5px;
  padding: 1rem;
}

.loading__content--white {
  background: #fff;
}

.loading__text {
  color: var(--default-font-color);
  display: block;
  font-family: var(--default-font);
  margin-top: 0.5rem;
}

.loading__text:empty {
  display: none;
}

.loading__spinner {
  -webkit-animation: clockwise 1s linear infinite;
          animation: clockwise 1s linear infinite;
  border: 4px solid #555;
  border-left-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  height: 2rem;
  width: 2rem;
  margin: 0 auto;
  position: relative;
}

@-webkit-keyframes clockwise {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes clockwise {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
