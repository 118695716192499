.button {
  background: white;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  display: inline-block;
  font: 400 18px/1 var(--default-font);
  outline: none;
  padding: 18px 24px;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.button:hover,
.button:focus {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  opacity: 0.8;
}

.button:active {
  box-shadow: none;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
  opacity: 0.8;
}

/* Block */
.button--block {
  display: block;
  width: 100%;
}

/* Success */
.button--success {
  border-color: #0ab155;
  background: #0ab155;
  color: white;
}

/* Danger */
.button--danger {
  border-color: #e74c3c;
  background: #e74c3c;
  color: white;
}

/* Info */
.button--info {
  border-color: #234581;
  background: #234581;
  color: white;
}

/* Facebook */
.button--facebook {
  border-color: #6081c4;
  color: #6081c4;
}

/* Icons */
.button > svg:first-child {
  margin-right: 0.5rem;
}

.button > svg:last-child {
  margin-left: 0.5rem;
}

/* disabled */
.button:disabled,
.button:disabled:hover {
  background: #e7e7e7;
  border-color: #e7e7e7;
  color: var(--default-font-color);
  box-shadow: none;
  cursor: default;
}

/* small */
.button--small {
  padding: 0.5rem;
  font-size: 14px;
}

/* big */
.button--big {
  padding: 1.25rem 1.5rem;
  font-size: 22px;
}
