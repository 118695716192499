.menu-mobile {
  -webkit-animation: appear 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
          animation: appear 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  background: rgba(0, 0, 0, 0.65);
  font-family: var(--default-font);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: none;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.menu-mobile--visible {
  display: block;
}

.menu-mobile--closing {
  display: block;
  -webkit-animation: disappear 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
          animation: disappear 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.menu-mobile--closing .menu-mobile__menu {
  -webkit-animation: slideOut 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
          animation: slideOut 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.menu-mobile__menu {
  -webkit-animation: slideIn 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
          animation: slideIn 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  -webkit-animation-delay: 300ms;
          animation-delay: 300ms;
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: -280px;
  visibility: hidden;
  top: 0;
  width: 280px;
}

.menu-mobile__header {
  background: #254886;
  box-sizing: border-box;
  color: #fff;
  padding: 0.5rem 1rem;
  position: relative;
  text-align: center;
}

.menu-mobile-user {
  line-height: 1.2;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.menu-mobile-user__name {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  width: 100%;
}

.menu-mobile-user__email {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.75rem;
  padding-bottom: 2px;
  width: 100%;
}

.menu-mobile-user__edit {
  line-height: 0;
  padding: 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.menu-mobile-user__edit::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.menu-mobile-user__edit svg {
  width: 15px;
  height: 15px;
}

.menu-mobile__content {
  flex: 1 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap;
}

.menu-mobile--submenu-active .menu-mobile__content {
  -webkit-transform: translateX(-280px);
          transform: translateX(-280px);
}

.menu-mobile__list {
  display: inline-block;
  vertical-align: top;
  list-style: none;
  margin: 0;
  padding: 2rem 0;
  white-space: normal;
  width: 100%;
}

.menu-mobile__submenu {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.menu-mobile__submenu-back {
  border: none;
  background: none;
  padding: 0;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  position: relative;
}

.menu-mobile__submenu-back::after,
.menu-mobile__submenu-back::before {
  content: '';
  position: absolute;
  left: 0;
  width: 12px;
  height: 3px;
  border-radius: 3px;
  background: #868686;
}

.menu-mobile__submenu-back::after {
  top: 8px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.menu-mobile__submenu-back::before {
  top: 15px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.menu-mobile__item:hover .menu-mobile__link,
.menu-mobile__item--active .menu-mobile__link {
  background: #f4f4f4;
}

.menu-mobile__link {
  display: block;
  font-size: 1rem;
  font-family: var(--default-font);
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: var(--default-font-color);
}

.menu-mobile__footer {
  border-top: 1px solid #e7e7e7;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.menu-mobile__foo-link {
  color: var(--default-font-color);
  font-size: 1rem;
  text-decoration: none;
}

.menu-mobile__logout {
  display: flex;
  align-items: center;
}

.menu-mobile__logout svg {
  margin-right: 5px;
  width: 18px;
  height: 18px;
}

@-webkit-keyframes appear {
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes appear {
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

@-webkit-keyframes disappear {
  from {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes disappear {
  from {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

@-webkit-keyframes slideIn {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    visibility: hidden;
  }

  to {
    -webkit-transform: translateX(280px);
            transform: translateX(280px);
    visibility: visible;
  }
}

@keyframes slideIn {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    visibility: hidden;
  }

  to {
    -webkit-transform: translateX(280px);
            transform: translateX(280px);
    visibility: visible;
  }
}

@-webkit-keyframes slideOut {
  from {
    -webkit-transform: translateX(280px);
            transform: translateX(280px);
    visibility: visible;
  }

  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    visibility: hidden;
  }
}

@keyframes slideOut {
  from {
    -webkit-transform: translateX(280px);
            transform: translateX(280px);
    visibility: visible;
  }

  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    visibility: hidden;
  }
}
