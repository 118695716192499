.data-table__sort-arrow {
  position: relative;
  background-color: var(--default-font-color);
  text-align: left;
  margin-left: 10px;
  margin-top: 5px;
  opacity: 0;
  vertical-align: top;
}

.data-table__sort-arrow--asc,
.data-table__sort-arrow--desc {
  opacity: 1;
}

.data-table__sort-arrow::before,
.data-table__sort-arrow::after {
  content: '';
  position: absolute;
  background-color: inherit;
}

.data-table__sort-arrow,
.data-table__sort-arrow:before,
.data-table__sort-arrow:after {
  width: 5px;
  height: 5px;
  border-top-right-radius: 30%;
}

.data-table__sort-arrow--asc {
  -webkit-transform: rotate(0) skewX(-30deg) scale(1, 0.866);
          transform: rotate(0) skewX(-30deg) scale(1, 0.866);
}

.data-table__sort-arrow--desc {
  -webkit-transform: rotate(180deg) skewX(-30deg) scale(1, 0.866);
          transform: rotate(180deg) skewX(-30deg) scale(1, 0.866);
  margin-top: 8px;
}

.data-table__sort-arrow::before {
  -webkit-transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
    translate(0, -50%);
          transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
    translate(0, -50%);
}

.data-table__sort-arrow::after {
  -webkit-transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
          transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}
