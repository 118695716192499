.header-desktop {
  background: #fff;
  box-shadow: var(--desktop-shadow);
  color: var(--default-font-color);
  font-family: var(--default-font);
  font-size: 1rem;
}

.header-desktop__content {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;
}

.header-desktop__left-content {
  display: flex;
  align-items: center;
}

.header-desktop__project-name {
  margin: 0 0 0 0.5rem;
}

.header-desktop__row--second {
  border-top: 1px solid #e7e7e7;
}

.header-desktop__row:first-child .header-desktop__content {
  padding: 0.5rem 1rem;
}

.header-desktop__list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.header-desktop__link {
  box-sizing: border-box;
  color: var(--default-font-color);
  cursor: pointer;
  display: block;
  padding: 0.7rem 1rem;
  text-decoration: none;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.header-desktop__item:hover .header-desktop__link,
.header-desktop__item--active .header-desktop__link {
  background: #f4f4f4;
}

.header-desktop__user {
  line-height: 0;
  cursor: pointer;
  position: relative;
}

.header-desktop__user-info {
  border-bottom: 1px solid #e7e7e7;
  padding: 1rem;
  white-space: nowrap;
}

.header-desktop__user-info > * {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.header-desktop__user:hover .header-desktop__user-dropdown {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.header-desktop__user-dropdown {
  line-height: 1;
  padding-top: 1rem;
  position: absolute;
  top: 100%;
  right: -1rem;
  width: 300px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(25%);
          transform: translateY(25%);
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.header-desktop__user-dropdown-content {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  cursor: default;
  position: relative;
}

.header-desktop__user-dropdown-content::before {
  background: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  content: '';
  width: 20px;
  height: 20px;
  position: absolute;
  right: 2rem;
  top: -10px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  z-index: 1;
}

.header-desktop__user-dropdown-content::after {
  background: #fff;
  border-radius: 5px;
  content: '';
  width: 50px;
  height: 20px;
  position: absolute;
  right: 1rem;
  top: 0;
  z-index: 2;
}

.header-desktop__user-list {
  border-radius: 0 0 8px 8px;
  list-style: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.header-desktop__user-link {
  box-sizing: border-box;
  color: var(--default-font-color);
  cursor: pointer;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.header-desktop__user-link:hover {
  background: #f4f4f4;
}
