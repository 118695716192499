.progress-bar {
  border-radius: 14px;
  display: block;
  height: 14px;
  position: relative;
  overflow: hidden;
}

.progress-bar__value {
  position: absolute;
  left: 0;
  top: 0;
  height: 14px;
  -webkit-transition: all .3s cubic-bezier(.4, 0, .2, 1);
  transition: all .3s cubic-bezier(.4, 0, .2, 1);
}
