.radio {
  display: flex;
  align-items: center;
  padding: 5px 0;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.radio__field {
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 15px;
  margin-right: 5px;
  height: 18px;
  width: 18px;
  position: relative;
}

.radio__label {
  color: #515151;
  font: 400 1rem/1 var(--default-font);
}

.radio__node {
  opacity: 0;
  position: absolute;
  z-index: -1;
  height: 18px;
  width: 18px;
  left: 0;
  top: 6px;
  margin: 0;
}

.radio__node:focus ~ .radio__field {
  border-color: #47baec;
}

.radio__node:active ~ .radio__field {
  background-color: #fafafa;
}

.radio__node:checked ~ .radio__field:before {
  background-color: #0ab155;
  border-radius: 6px;
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 6px;
  height: 6px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.radio__node:disabled ~ .radio__field {
  background-color: #f4f4f4;
}

.radio__node:disabled ~ .radio__label {
  color: var(--default-font-color);
}
