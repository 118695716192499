.floating-button {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  height: 50px;
  width: 50px;
  z-index: 999;
}

.floating-button__button {
  background: #0ab155;
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.35);
  font-size: 0;
  outline: none;
  width: 50px;
  height: 50px;
}

.floating-button__button::after,
.floating-button__button::before {
  content: '';
  background: #fff;
  border-radius: 3px;
  position: absolute;
  height: 3px;
  width: 24px;
  left: 50%;
  margin-left: -12px;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.floating-button__button::after {
  top: 20px;
}

.floating-button__button::before {
  top: 28px;
}

.floating-button__button--open::after {
  -webkit-transform: translateY(4px) rotate(45deg);
          transform: translateY(4px) rotate(45deg);
}

.floating-button__button--open::before {
  -webkit-transform: translateY(-4px) rotate(-45deg);
          transform: translateY(-4px) rotate(-45deg);
}

.floating__list {
  position: absolute;
  bottom: 60px;
  right: 0;
  margin: 0;
  padding: 0;
  text-align: right;
  list-style: none;
  white-space: nowrap;
}

.floating__link {
  -webkit-animation: moveIn 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
          animation: moveIn 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  background: #fff;
  border-radius: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.35);
  box-sizing: border-box;
  color: var(--default-font-color);
  cursor: pointer;
  display: inline-block;
  font: 400 1rem/1rem var(--default-font);
  margin-top: 1rem;
  opacity: 0;
  padding: 0.5rem 1rem;
  text-decoration: none;
}

.floating__list li:nth-child(1) a {
  -webkit-animation-delay: 50ms;
          animation-delay: 50ms;
}
.floating__list li:nth-child(2) a {
  -webkit-animation-delay: 100ms;
          animation-delay: 100ms;
}
.floating__list li:nth-child(3) a {
  -webkit-animation-delay: 150ms;
          animation-delay: 150ms;
}
.floating__list li:nth-child(4) a {
  -webkit-animation-delay: 200ms;
          animation-delay: 200ms;
}
.floating__list li:nth-child(5) a {
  -webkit-animation-delay: 250ms;
          animation-delay: 250ms;
}
.floating__list li:nth-child(6) a {
  -webkit-animation-delay: 300ms;
          animation-delay: 300ms;
}
.floating__list li:nth-child(7) a {
  -webkit-animation-delay: 350ms;
          animation-delay: 350ms;
}
.floating__list li:nth-child(8) a {
  -webkit-animation-delay: 400ms;
          animation-delay: 400ms;
}

@-webkit-keyframes moveIn {
  from {
    opacity: 0;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes moveIn {
  from {
    opacity: 0;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
