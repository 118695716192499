.avatar {
  background: #fff;
  border: 2px solid #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  display: inline-block;
  line-height: 0;
  margin: 0;
  width: 65px;
  height: 65px;
  position: relative;
}

.avatar--rounded {
  border-radius: 50%;
  overflow: hidden;
}

.avatar--small {
  width: 50px;
  height: 50px;
}

.avatar--big {
  width: 128px;
  height: 128px;
}

.avatar__image {
  max-width: 100%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0;
}
