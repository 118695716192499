.header {
  background: #254886;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 60px 0.5rem;
  position: relative;
}

.header__title {
  color: #fff;
  font: 400 18px/20px var(--default-font);
  margin: 0;
}

.menu-button {
  box-sizing: border-box;
  cursor: pointer;
  height: 20px;
  width: 30px;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.menu-button::after,
.menu-button::before,
.menu-button span {
  content: '';
  background: #fff;
  border-radius: 3px;
  position: absolute;
  height: 3px;
  width: 55%;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.menu-button span {
  -webkit-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.menu-button::after {
  top: 0;
  left: 0;
  -webkit-transform-origin: left top;
          transform-origin: left top;
}

.menu-button::before {
  bottom: 0;
  right: 0;
  -webkit-transform-origin: right bottom;
          transform-origin: right bottom;
}

.menu-button span {
  top: 8px;
  width: 100%;
  -webkit-transform-origin: center;
          transform-origin: center;
}

.menu-button--open::after {
  -webkit-transform: rotate(45deg) translate(2px, -5px);
          transform: rotate(45deg) translate(2px, -5px);
}

.menu-button--open::before {
  -webkit-transform: rotate(45deg) translate(-3px, 5px);
          transform: rotate(45deg) translate(-3px, 5px);
}

.menu-button--open span {
  -webkit-transform: rotate(315deg);
          transform: rotate(315deg);
}
