@media screen and (min-width: 1200px) {
  .paginate-arrows {
    display: inline-block;
  }
}

.paginate-arrows--full {
  display: flex;
  justify-content: space-between;
}

.paginate__info {
  color: var(--default-font-color);
  font-family: var(--default-font);
  margin: 0;
  display: inline-block;
  vertical-align: top;
  min-width: 120px;
  padding-top: 5px;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.paginate__arrow {
  border-radius: 30px;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 30px;
  width: 30px;
  -webkit-transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

.paginate__arrow:hover {
  background: #e7e7e7;
}

.paginate__arrow::after,
.paginate__arrow::before {
  background: #868686;
  content: '';
  position: absolute;
  left: 8px;
  width: 12px;
  height: 3px;
  border-radius: 3px;
}

.paginate__arrow::after {
  top: 10px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.paginate__arrow::before {
  top: 17px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.paginate__arrow-next {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

/* next disabled */
.paginate__arrow--disabled:hover {
  background: none;
  cursor: default;
}

.paginate__arrow--disabled::after,
.paginate__arrow--disabled::before {
  background: #ccc;
}
