.logo a {
  display: block;
}

.logo__img {
  display: block;
  max-width: 100%;
}

.logo--link {
  cursor: pointer;
}

.logo--white {
  -webkit-filter: brightness(100);
          filter: brightness(100);
}
