.action-button {
  width: 30px;
  height: 30px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.action-button--visible {
  z-index: 999;
}

.action-button__icon {
  background: #fff;
  border: 2px solid #fff;
  border-radius: 30px;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2;
}

.action-button:not(.action-button--visible):hover .action-button__icon {
  border-color: #c4c4c4;
}

.action-button__icon::after,
.action-button__icon::before,
.action-button__icon > span {
  background: #c4c4c4;
  border-radius: 4px;
  content: '';
  width: 4px;
  height: 4px;
  position: absolute;
  left: 50%;
  top: 13px;
  -webkit-transform: translate(-50%, -50%) rotate(0);
          transform: translate(-50%, -50%) rotate(0);
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.action-button__icon::after {
  top: 19px;
}

.action-button__icon::before {
  top: 7px;
}

.action-button--visible .action-button__icon > span {
  opacity: 0;
}

.action-button--visible .action-button__icon::after {
  -webkit-transform: translate(-50%, -8px) rotate(-135deg);
          transform: translate(-50%, -8px) rotate(-135deg);
  width: 20px;
}

.action-button--visible .action-button__icon::before {
  -webkit-transform: translate(-50%, 4px) rotate(135deg);
          transform: translate(-50%, 4px) rotate(135deg);
  width: 20px;
}

.action-button__list {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
  list-style: none;
  margin: 0;
  opacity: 0;
  overflow: hidden;
  padding: 2rem 0 0;
  position: absolute;
  visibility: hidden;
  right: 0;
  top: 0;
  z-index: 1;
}

.action-button__item {
  color: var(--default-font-color);
  cursor: pointer;
  font-family: var(--default-font);
  padding: 0.5rem 1rem;
  min-width: 150px;
  -webkit-transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.action-button__item--danger {
  color: #e74c3c;
}

.action-button__item:hover {
  background: #e7e7e7;
}

.action-button--visible .action-button__list {
  opacity: 1;
  visibility: visible;
}
