.timeline {
  list-style: none;
  margin: 0;
  padding: 0 0 0.5rem;
  position: relative;
}

ul.timeline::before {
  background: #c4c4c4;
  content: '';
  position: absolute;
  left: 145px;
  top: 20px;
  width: 1px;
  height: calc(100% - 20px);
}

.timeline__item {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
}

.timeline__item:first-child .timeline__date {
  color: #fff;
}

.timeline__dot {
  background: #c4c4c4;
  border-radius: 7px;
  content: '';
  position: absolute;
  left: 142px;
  height: 7px;
  width: 7px;
}

.timeline__title,
.timeline__text,
.timeline__date {
  font-family: var(--default-font);
}

.timeline__title {
  color: var(--default-font-color);
  font-size: 1.125rem;
  margin: 0;
}

.timeline__text {
  color: #989898;
  font-size: 0.75rem;
  line-height: 1;
  margin: 0;
}

.timeline__date {
  background: #e7eef3;
  border-radius: 6px;
  box-sizing: border-box;
  color: var(--default-font-color);
  flex-shrink: 0;
  font-size: 0.75rem;
  height: 32px;
  line-height: 32px;
  margin-right: 2rem;
  padding: 0 0.5rem;
  position: relative;
}

.timeline__date-text {
  position: relative;
  z-index: 2;
}

.timeline__arrow {
  background: #e7eef3;
  content: '';
  position: absolute;
  right: -8px;
  top: 3px;
  width: 26px;
  height: 26px;
  border-radius: 6px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  z-index: 1;
}

@media (min-width: 1200px) {
  ul.timeline::before {
    left: 158px;
  }

  .timeline__dot {
    left: 155px;
  }

  .timeline__date {
    padding: 0 1rem;
  }
}
