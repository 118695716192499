.input {
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  margin-bottom: 1rem;
}

.input__label {
  color: rgba(0, 0, 0, 0.54);
  font: 400 18px/20px var(--default-font);
  position: absolute;
  left: 20px;
  top: 20px;
  -webkit-transition: -webkit-transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transform-origin: left top;
          transform-origin: left top;
  width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}

.input__field {
  background: transparent;
  border-radius: 5px;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  color: var(--default-font-color);
  font: 400 18px/24px var(--default-font);
  margin: 0;
  min-height: 62px;
  width: 100%;
  outline: none;
  padding: 25px 17px 11px;
  -webkit-transition: border 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: border 300ms cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-appearance: none;
}

.input__field:not([value='']) ~ .input__label,
.input__field:focus ~ .input__label {
  color: rgba(0, 0, 0, 0.87);
  -webkit-transform: scale(0.7) translate(-15px, -15px);
          transform: scale(0.7) translate(-15px, -15px);
}

.input__field:focus {
  border-color: #47baec;
}

.input__field--alert,
.input__field--alert:focus {
  border-color: #ffc107;
}

.input__field--success,
.input__field--success:focus {
  border-color: #0ab155;
}

.input__field--error,
.input__field--error:focus {
  border-color: #e74c3c;
}

.input__error-message:not(:empty) {
  color: #e74c3c;
  font: 400 0.75rem var(--default-font);
  margin: 0;
  padding: 0 0.5rem;
}

.input__error-message:empty {
  margin: 0;
}

.input__field:disabled {
  background-color: #f4f4f4;
  opacity: 1;
}

.input__field:disabled ~ .input__label {
  color: var(--default-font-color);
}

input:-webkit-autofill ~ label {
  -webkit-transform: scale(0.7) translate(-15px, -15px);
          transform: scale(0.7) translate(-15px, -15px);
}

.input__card {
  position: absolute;
  right: 15px;
  top: 15px;
  height: 30px;
  width: 40px;
}

.input__password-icon {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  width: 60px;
  height: 100%;
}

.input__password-icon [icon='eye-close'] {
  -webkit-transition: 200ms all ease-in-out;
  transition: 200ms all ease-in-out;
}

.input__password-icon [icon='eye-open'] {
  margin-top: -3px;
  -webkit-transition: 200ms all ease-in-out;
  transition: 200ms all ease-in-out;
}

.input__password--hide [icon='eye-close'] {
  -webkit-transform: rotateX(180deg) translateY(5px);
          transform: rotateX(180deg) translateY(5px);
  -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
}

.input__password--hide [icon='eye-open'] {
  opacity: 0;
}

.input-cc-expanded {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

.input-cc-expanded li {
  line-height: 0;
  margin-bottom: 10px;
  text-align: center;
  width: 20%;
}

@media (min-width: 992px) {
  .input-cc-expanded li {
    margin-bottom: 5px;
    width: 12.5%;
  }
}

.input-cc-expanded li:empty {
  margin: 0;
}

.input-cc-expanded svg {
  cursor: pointer;
  opacity: 0.25;
}

.input-cc-expanded svg:hover,
.input-cc-expanded .active {
  opacity: 1;
}

/* reset input number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
