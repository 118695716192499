.option {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  align-items: center;
  font: 400 1rem/1.5rem var(--default-font);
  margin: 1rem 0;
  outline: none;
  padding: 1rem;
  padding-right: 2rem;
  position: relative;
  text-align: left;
  text-decoration: none;
  width: 100%;
}

.option:hover,
.option:focus,
.option:visited {
  background: #fafafa;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}

.option:after {
  border: solid rgba(0, 0, 0, 0.4);
  border-width: 0 1px 1px 0;
  content: '';
  padding: 5px;
  position: absolute;
  right: 1rem;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(-45deg);
          transform: translateY(-50%) rotate(-45deg);
}

.option .icon {
  flex: 0 1 50px;
  fill: rgba(0, 0, 0, 0.4);
  height: 30px;
  margin-right: 15px;
  width: 50px;
}

.option--error {
  -webkit-animation: shake 0.82s cubic-bezier(0.4, 0, 0.2, 1) both;
          animation: shake 0.82s cubic-bezier(0.4, 0, 0.2, 1) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

/* Danger State */
.option--danger {
  background: #fffbfb;
  border-color: #e74c3c;
  color: #e74c3c;
}

.option--danger:after {
  border-color: #e74c3c;
}

.option--danger .icon {
  fill: #e74c3c;
}

.option--danger:hover,
.option--danger:focus,
.option--danger:active {
  background: #ffeaea;
  color: #e74c3c;
}

/* Success State */
.option--success {
  background: #fffbfb;
  border-color: #0ab155;
  color: #0ab155;
}

.option--success:after {
  border-color: #0ab155;
  border-width: 0 3px 3px 0;
  -webkit-transform: translateY(-55%) rotate(45deg);
          transform: translateY(-55%) rotate(45deg);
  height: 25px;
}

.option--success .icon {
  fill: #0ab155;
}

.option--success:hover,
.option--success:focus,
.option--success:active {
  color: #0ab155;
}

@media screen and (min-width: 768px) {
  .option {
    padding: 15px 30px;
    padding-right: 50px;
  }

  .option:after {
    right: 30px;
  }
}

@-webkit-keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}
