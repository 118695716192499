.checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.checkbox__field {
  background: #fff;
  border: 2px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
  margin-right: 5px;
  height: 22px;
  width: 22px;
  position: relative;
}

.checkbox__label {
  color: #515151;
  font: 400 1rem/1 var(--default-font);
}

.checkbox__node {
  opacity: 0;
  position: absolute;
  z-index: -1;
  height: 17px;
  width: 17px;
  left: 0;
  top: 6px;
  margin: 0;
}

.checkbox__node:focus ~ .checkbox__field {
  border-color: #47baec;
}

.checkbox__node:active ~ .checkbox__field {
  background-color: #fafafa;
}

.checkbox__node:checked ~ .checkbox__field:before {
  box-sizing: border-box;
  content: '';
  position: absolute;
  left: 3px;
  top: 4px;
  width: 12px;
  height: 7px;
  border-bottom: 2px solid #0ab155;
  border-left: 2px solid #0ab155;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.checkbox__node:disabled ~ .checkbox__field {
  background-color: #f4f4f4;
}

.checkbox__node:disabled ~ .checkbox__label {
  color: var(--default-font-color);
}
