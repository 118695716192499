.data-table {
  background: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  color: var(--default-font-color);
  font-family: var(--default-font);
}

.data-table thead {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.data-table__table {
  border-collapse: collapse;
  width: 100%;
}

.data-table__row {
  border-top: 1px solid #e7e7e7;
}

.data-table__row:hover,
.data-table__row--selected {
  background: #f4f4f4;
}

.data-table__row--clickable {
  cursor: pointer;
}

.data-table__row:last-child td {
  padding-bottom: 1rem;
}

.data-table__col {
  border-right: 1px solid #e7e7e7;
  box-sizing: border-box;
  padding: 1rem;
  white-space: nowrap;
}

.data-table__col:last-child {
  border-right: none;
}

.data-table__col--checkbox {
  border-right: none;
  padding-right: 0;
}

.data-table__col span {
  cursor: pointer;
}

.data-table th {
  text-align: left;
  padding-top: 1rem;
}

.data-table__empty {
  padding: 0 1rem;
  text-align: center;
}

.data-table__sort-arrow {
  display: inline-block;
  width: 5px;
  font-size: 1.5rem;
  line-height: 0;
  font-weight: 700;
}
