.input-locked {
  display: flex;
  position: relative;
}

.input-locked--disabled {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.input-locked--disabled .input:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.input-locked .input {
  flex: 1 1;
}

.input-locked__button,
.input-locked__button:visited,
.input-locked__button--hidden {
  background: #f4f4f4;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 0 8px 8px 0;
  border-left: 1px solid rgba(0, 0, 0, 0.24);
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.55);
  cursor: pointer;
  margin-left: -8px;
  padding: 0 1rem;
  position: relative;
  z-index: 2;
  height: 62px;
  text-decoration: none;
  text-transform: uppercase;
  font: 400 12px/58px var(--default-font);
}

.input-locked__button--hidden {
  -webkit-transition: all 400ms ease-in;
  transition: all 400ms ease-in;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.input-locked__button:hover {
  background: #f4f4f4;
}

.input-locked .input__field--locked {
  background: #f4f4f4;
  border-radius: 5px 0 0 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (min-width: 992px) {
  .input-locked__button,
  .input-locked__button--hidden {
    padding: 0 2rem;
  }
}
